import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModifyESim() {
  const [show, setShow] = useState(false);

  const [simId, setSimId] = useState('');
  const [smdp, setSmdp] = useState('');
  const [qrCode, setQrCode] = useState<File | null>(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleQrCodeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setQrCode(e.target.files[0]);
    }
  };

  // const handleCancelQrCode = () => {
  //   setQrCode(null);
  // };

  return (
    <>
      <button
        className="rounded-pill border-0 px-4" style={{ backgroundColor: "#03FE9D", fontWeight: "600" }} onClick={handleShow}
      >
        Modify
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className='text-light'
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title>Modify Individual Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="mb-3">
        {qrCode && (
              <div className="mt-2 text-center position-relative">
                {/* <button
                  type="button"
                  className="btn-close position-absolute top-0 end-0 p-2"
                  onClick={handleCancelQrCode}
                  aria-label="Cancel QR Code"
                /> */}
                <img
                  src={URL.createObjectURL(qrCode)}
                  alt="QR Code preview"
                  style={{ maxWidth: '200px' }}
                />
              </div>
            )}
            <label htmlFor="qrCode" className="form-label">QR Code Image</label>
            <input
              type="file"
              className="form-control"
              id="qrCode"
              accept="image/*"
              onChange={handleQrCodeUpload}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="simId" className="form-label">SIM ID</label>
            <input
              type="text"
              className="form-control"
              id="simId"
              value={simId}
              onChange={(e) => setSimId(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="smdp" className="form-label">SMDP</label>
            <input
              type="text"
              className="form-control"
              id="smdp"
              value={smdp}
              onChange={(e) => setSmdp(e.target.value)}
            />
          </div>
          <div className="mb-3 d-flex justify-content-center">
    <button className="rounded-pill p-2 w-75 border-0 px-4" style={{ backgroundColor: "#03FE9D", fontWeight: "600" }} >Submit </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModifyESim;