import { toast } from "react-toastify";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams } from "react-router-dom";

import styles from "../styles/viewPlans.module.css";
import commonStyles from '../../../lib/common/common.module.css';
import { PlanTypes } from "../../../interfaces";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import { getNiyoPlans, getPlans, updatePlan, updatePopularCountries, uploadPlanFile } from "../api";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout, Pagination } from "../../../ui";
import { Toggle } from "../../../components";
import Swal from 'sweetalert2';


const ViewPlans = () => {
  const params = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const countryName = queryParams.get("country");

  const getURLPath = location?.pathname?.split('/')
  const getLast = getURLPath[1]
  const getPathName = (LOCAL_CONSTANTS?.LASTPATHNAME?.SANDBOX_PLANS === getLast)

  const [plans, setPlans] = useState<{ list: PlanTypes.Plan[], count: number }>({ list: [], count: 0 });
  const [loading, setLoading] = useState<boolean>(true);


  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [retailPrice, setPlanRetailPrice] = useState({ value: 0, planId: "" });
  const [offerPrice, setPlanOfferPrice] = useState({ value: 0, planId: "" });
  const [buyPrice, setPlanBuyPrice] = useState({ value: 0, planId: "" });
  const [activeTab, setActiveTab] = useState<number>(1);
  const [popularCountoryChecked, setPopularCountoryChecked] = useState<boolean>(true);
  const [pouplarCountryCount, setPouplarCountryCount] = useState<number>(0);
  const [exportExcel, setExportExcel] = useState()
  const getUserType = params?.partnerName?.toUpperCase() as keyof typeof LOCAL_CONSTANTS.PARTNERTYPES ?? '';
  const userType: number = getPathName ? 0 : !LOCAL_CONSTANTS?.PARTNERTYPES[getUserType] ? 1 : LOCAL_CONSTANTS?.PARTNERTYPES[getUserType];

  const listPlans = () => {
    let query: any = {
      page: (pagination.skip + 1),
      limit: pagination?.limit,
      vendor: activeTab,
      countryId: params.id,
      userType: userType
    };



    // if (window.location.pathname.startsWith('/plans/countries')) {
    //   query["countryId "] = params.id;
    // }
    // else query["countryId "] = params.id;
    if (userType === 2 || userType === 4 ) {
      getNiyoPlans(query).then((res: any) => {
        const bundles: any = res.data.bundles;
        setPouplarCountryCount(res?.data?.popularBundlesCount)
        for (const item of bundles) {

          let SecondAmount = item.buyPrice ? item.buyPrice : item.apiPrice;
          let firstAmount = item.offerPrice ? item.offerPrice : item.retailPrice ? item.retailPrice : item.apiPrice;

          item.marginPrice = SecondAmount - firstAmount;
          item.marginPercent = item.marginPrice / 100;
        }
        setPlans({ list: bundles, count: res.data.count });
        setLoading(false);
      });
    } else {

      getPlans(query).then((res: any) => {
        const bundles: any = res.data.bundles;
        setExportExcel(res?.data?.excelUrl)
        console.log(res, " h hhgg g g gg g g g g g gg  g g g g g g g g g")
        setPouplarCountryCount(res?.data?.popularBundlesCount)
        for (const item of bundles) {

          let SecondAmount = item.buyPrice ? item.buyPrice : item.apiPrice;
          let firstAmount = item.offerPrice ? item.offerPrice : item.retailPrice ? item.retailPrice : item.apiPrice;

          item.marginPrice = SecondAmount - firstAmount;
          item.marginPercent = item.marginPrice / 100;
        }
        setPlans({ list: bundles, count: res.data.count });
        setLoading(false);
      });
    }

  };


  const togglePlanRetailPrice = (item: PlanTypes.Plan) => {
    if (retailPrice.planId !== item._id || !retailPrice.planId.length) {
      setPlanRetailPrice({ value: item.retailPrice ?? 0, planId: item._id })
    }
  };

  const togglePlanOfferPrice = (item: PlanTypes.Plan) => {
    if (item.retailPrice) {
      if (offerPrice.planId !== item._id || !offerPrice.planId.length) {
        setPlanOfferPrice({ value: item.offerPrice ?? 0, planId: item._id })
      }
    }
    else toast.error("You need to add retail amount before adding offer amount");
  };

  const togglePlanBuyPrice = (item: PlanTypes.Plan) => {
    if (buyPrice.planId !== item._id || !buyPrice.planId.length) {
      setPlanBuyPrice({ value: item.buyPrice ?? 0, planId: item._id })
    }
  };


  const updatePlanData = (plan: PlanTypes.Plan, userType?: number, newPlanPrice?: number, priceType?: string,) => {

    let payload: any = { isActive: !plan.isActive, userType: userType };

    // if( !userType) payload.userType = userType;

    if (typeof newPlanPrice === "number") {
      let key = priceType === "retail" ? "retailPrice" : priceType === "buy" ? "buyPrice" : "offerPrice";

      payload[key] = newPlanPrice;
    }
    else {
      if (!plan.retailPrice && !plan.isActive) {
        toast.error(CONSTANTS.ENABLE_PLAN_WITHOUT_RETAIL_AMOUNT);
        return;
      }
    };


    toast.promise(
      updatePlan(payload, plan._id),
      {
        pending: {
          render() {
            return CONSTANTS.PLANS.UPDATING_STATUS;
          },
        },
        success: {
          render() {
            listPlans();
            setPlanRetailPrice({ planId: "", value: 0 });
            setPlanOfferPrice({ planId: "", value: 0 });
            setPlanBuyPrice({ planId: "", value: 0 });
            return CONSTANTS.PLANS.UPDATE_SUCCESS;
          }
        },
        error: {
          render() {
            return CONSTANTS.PLANS.UPDATE_FAILED;
          }
        }
      }
    )
  }

  const updateDocuments = (documentsRequired: boolean, planId: string) => {
    let payload: any = { documentsRequired };

    toast.promise(
      updatePlan(payload, planId),
      {
        pending: {
          render() {
            return CONSTANTS.PLANS.UPDATING_STATUS;
          },
        },
        success: {
          render() {
            listPlans();

            return CONSTANTS.PLANS.UPDATE_SUCCESS;
          }
        },
        error: {
          render({ data }: any) {
            return data.data.message;
          }
        }
      }
    )
  };

  const updatePopularPlan = (isPopular: boolean, planId: string) => {
    let payload: any = { isPopular };

    toast.promise(
      updatePlan(payload, planId),
      {
        pending: {
          render() {
            return CONSTANTS.PLANS.UPDATING_STATUS;
          },
        },
        success: {
          render() {
            listPlans();

            return CONSTANTS.PLANS.UPDATE_SUCCESS;
          }
        },
        error: {
          render({ data }: any) {
            return data.data.message;
          }
        }
      }
    )
  };

  const handleRetailPriceChange = (event: any) => {
    setPlanRetailPrice({ ...retailPrice, value: parseFloat(event.target.value) })
  };

  const handleOfferPriceChange = (event: any) => {
    setPlanOfferPrice({ ...offerPrice, value: parseFloat(event.target.value) })
  };

  const handleBuyPriceChange = (event: any) => {
    setPlanBuyPrice({ ...buyPrice, value: parseFloat(event.target.value) })
  };

  useEffect(() => {
    listPlans();
  }, [activeTab, popularCountoryChecked, pagination]);

  const handelPopularCountry = (e: any) => {
    if (e.target.checked == true && pouplarCountryCount >= 3) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'You can not select more then 3 plans!',
      });
      return false
    }


    const query = {
      "countryId": params.id,
      "popularBundleId": e.target.value
    }
    updatePopularCountries(query).then((res) => {
      setPopularCountoryChecked(!popularCountoryChecked)
      e.target.checked ? toast.success("Popular country plas has been unselected") : toast.success("Popular country plas has been selected")

    }).catch((error) => {
      toast.error(error?.data?.message || "Something went wrong!")
    })
  }

  const handleUploadPlans = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    uploadPlanFile(formData)
      .then((response:any) => {
        toast.success(`${file.name} uploaded successfully!`);
        listPlans(); // Refresh the plans after upload
      })
      .catch((error:any) => {
        toast.error(`Failed to upload ${file.name}.`);
      });
  };
    console.log("🚀 ~ handleUploadPlans ~ uploadPlanFile:", uploadPlanFile)
    console.log("🚀 ~ handleUploadPlans ~ uploadPlanFile:", uploadPlanFile)

  return (
    <Layout title={getPathName ? `Sandbox plans for ${countryName}` : params?.partnerName ? `${params?.partnerName.charAt(0).toUpperCase()+params?.partnerName.slice(1)} plans for ${countryName}` : `Plans for ${countryName} `}>
      <div className={commonStyles.tableData}>
        <div className={commonStyles.mainTabbing}>
          <div className="leftTabbing">
            <div className={commonStyles.topCms}>
              <ul>
                <li className={activeTab === 1 ? commonStyles.active : ""}
                  onClick={() => {
                    setActiveTab(1)
                  }}><p>G+</p></li>
                <li className={activeTab === 2 ? commonStyles.active : ""}
                  onClick={() => {
                    setActiveTab(2)
                  }}><p>Commbitz</p></li>
                  <li><a href={exportExcel} className="btn btn-success"> Export Data</a></li>
                  <label className="btn btn-primary" style={{ marginBottom: "10px", cursor: "pointer" }}>
                    Upload Plans
                    <input
                      type="file"
                      accept=".csv,.xlsx,.xls,.json,.pdf,.docx, .ods" // Accept multiple file types
                      style={{ display: "none" }}
                      onChange={handleUploadPlans}
                    />
                  </label>
                  <label>
  {/* View Sample Button (Download Link) */}
  <a
    href="https://api-cb.commbitz.com/public/bundle_Sample_1739172044140.xlsx"
    download="bundle_Sample.xlsx"
    className="btn btn-secondary"
  >
    View Sample
  </a>
  </label>

              </ul>
            </div>
          </div>
          {(!getPathName && !params?.partnerName) && <div><p style={{ color: "rgb(0 0 0)", background: "#ffff00" }} >Note : : <span>Only 3 plans can be selected, not less than 3 and more than 3 will be saved.</span></p></div>}

        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              {(!getPathName && !params?.partnerName) && <th>Popular Countries</th>}
              <th>Plan name</th>
              {/* <th>Vendor</th> */}
              <th>Description</th>
              <th>Product Code</th>
              <th>Validity</th>
              <th>Data</th>
              <th>Network</th>
              <th>Plan Amount</th>
              <th>Retail Amount</th>
              <th>Offer Amount</th>
              <th>Buy Amount</th>
              <th>Margin Price</th>
              <th>Margin %</th>
              <th>Total Sales</th>
              <th>Enable / Disable</th>
              <th>Document Required ?</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={12} align="center" className={styles.loadingText}>
                  {LOCAL_CONSTANTS.loading}
                </td>
              </tr>
            )}
            {!loading && plans.list.length === 0 && (
              <tr>
                <td colSpan={12} align="center">
                  No Plan Found
                </td>
              </tr>
            )}
            {!loading &&
              plans.list.map((item: any) => (
                <tr key={item._id}>
                  <td>{item?._id}</td>
                  {(!getPathName && !params?.partnerName) &&
                    <td>
                      <input type="checkbox" onChange={handelPopularCountry} checked={item.isCountryPopularBundle} name="popularCountries" value={item._id} />
                    </td>}

                  <td>{item.name}</td>
                  {/* <td>{item?.vendor === 1 ? "G+" : "Commbitz"}</td> */}
                  <td>{item?.description}</td>
                    <td>{item?.productCode}</td>
                  <td>{item.duration} Days</td>
                  <td>{item?.unlimited ? "Unlimited" : item?.perDay ? (item?.dataAmount / 1000) + "/day GB" : (item?.dataAmount / 1000) + " GB"}</td>
                  <td>{item.speed ? item.speed.join("/") : "-"}</td>
                  <td>{item.priceCurrency} {item.apiPrice}</td>
                  <td className="cursor-pointer" onClick={() => togglePlanRetailPrice(item)}>
                    {
                      item._id === retailPrice.planId ?
                        <Fragment>
                          <input
                            className={styles.input}
                            value={retailPrice.value}
                            type="number"
                            onChange={handleRetailPriceChange}
                            onKeyDown={(event) => {
                              if (event.keyCode === 13) updatePlanData(item, userType, retailPrice.value, "retail");
                            }}
                          />
                          <FontAwesomeIcon
                            className={styles.removePrice}
                            onClick={() => setPlanRetailPrice({ planId: "", value: 0 })}
                            icon={faTimes}
                          />

                        </Fragment>
                        :
                        item.retailPrice ? `${item.priceCurrency} ${item.retailPrice}` : "-"
                    }
                  </td>
                  <td className="cursor-pointer" onClick={() => togglePlanOfferPrice(item)}>
                    {
                      item._id === offerPrice.planId ?
                        <Fragment>
                          <input
                            className={styles.input}
                            value={offerPrice.value}
                            type="number"
                            onChange={handleOfferPriceChange}
                            onKeyDown={(event) => {
                              if (event.keyCode === 13) updatePlanData(item, userType, offerPrice.value, "offer");
                            }}
                          />

                          <FontAwesomeIcon
                            className={styles.removePrice}
                            onClick={() => setPlanOfferPrice({ planId: "", value: 0 })}
                            icon={faTimes}
                          />
                        </Fragment>
                        :
                        item.offerPrice ? `${item.priceCurrency} ${item.offerPrice}` : "-"
                    }
                  </td>
                  <td className="cursor-pointer" onClick={() => togglePlanBuyPrice(item)}>
                    {
                      item._id === buyPrice.planId ?
                        <Fragment>
                          <input
                            className={styles.input}
                            value={buyPrice.value}
                            type="number"
                            onChange={handleBuyPriceChange}
                            onKeyDown={(event) => {
                              if (event.keyCode === 13) updatePlanData(item, userType, buyPrice.value, "buy");
                            }}
                          />

                          <FontAwesomeIcon
                            className={styles.removePrice}
                            onClick={() => setPlanBuyPrice({ planId: "", value: 0 })}
                            icon={faTimes}
                          />
                        </Fragment>
                        :
                        item.buyPrice ? `${item.priceCurrency} ${item.buyPrice}` : "-"
                    }
                  </td>
                  <td>{item.marginPrice?.toFixed(2)}</td>
                  <td>{(item.marginPercent < 0 ? item.marginPercent * -1 : item.marginPercent)?.toFixed(2)}%</td>
                  <td>{item.bundleSalesCount}</td>
                  <td><Toggle handleChange={() => updatePlanData(item, userType)} value={item.isActive} /></td>
                  <td><Toggle handleChange={() => updateDocuments(!item.documentsRequired, item._id)} value={item.documentsRequired} /></td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={plans.count}
        />
      </div>


    </Layout>
  );
};

export default ViewPlans;